const Footer = () => {
  return (
    <footer className="my-footer">
      <small>
        &copy; 2024 <a href="/">Advokat Lipovan</a> by
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://webdevelopermaxi.eu.org"
        >
          <span> Maxi </span>
        </a>
        .{' '}
        <a
          href="https://www.termsfeed.com/live/fc0dcdfd-e766-432a-b36a-49d0d9669c8c"
          target="_blank"
          rel="noopener noreferrer"
        >
          All Rights Reserved.
        </a>
      </small>
    </footer>
  );
};

export default Footer;
