const data = {
  krivicno_pravo: [
    `Krivično pravo`,
    `Nikome ne može biti izrečena kazna ili druga krivična sankcija za delo koje pre nego što je učinjeno zakonom nije bilo određeno kao krivično delo, niti mu se može izreći kazna ili druga krivična sankcija koja zakonom nije bila propisana pre nego što je krivično delo učinjeno.`,
  ],
  porodicno_pravo: [
    `Porodično pravo`,
    `Ovim zakonom uređuju se: brak i odnosi u braku, odnosi u vanbračnoj zajednici, odnosi deteta i roditelja, usvojenje, hraniteljstvo, starateljstvo, izdržavanje, imovinski odnosi u porodici, zaštita od nasilja u porodici, postupci u vezi sa porodičnim odnosima i lično ime.`,
  ],
  gradjansko_pravo: [
    `Građansko pravo`,
    `Zakonik uređuje opšti deo građanskog prava, ugovorne i druge obligacione odnose, pravo svojine i druga stvarna prava, porodične odnose i nasleđivanje.`,
  ],
  privredno_pravo: [
    `Privredno pravo`,
    `Ovim zakonom uređuje se pravni položaj privrednih društava i drugih oblika organizovanja u skladu sa ovim zakonom, a naročito njihovo osnivanje, upravljanje, statusne promene, promene pravne forme, prestanak i druga pitanja od značaja za njihov položaj, kao i pravni položaj preduzetnika.`,
  ],
  nasledno_pravo: [
    `Nasledno pravo`,
    `Nasledno pravo podrazumeva da nasleđivanje može biti zakonsko kad su naslednici najčešce bliski srodnici preminulog ili testamentarno kada se kao naslednici pojavljuju lica van kruga zakonskih naslednika koja su ostaviocu bila posebno draga.`,
  ],
  obligacioni_odnosi: [
    `Obligacioni odnosi`,
    `Ovim zakonom uređuju se obligacioni odnosi koji nastaju iz ugovora, prouzrokovanja štete, sticanja bez osnova, poslovodstva bez naloga, jednostrane izjave volje i drugih zakonom utvrđenih činjenica.`,
  ],
  zastupanje_lica: [
    `Zastupanje lica`,
    `Zastupanje fizičkih i pravnih lica pred sudovima i drugim državnim organima u svim postupcima.`,
  ],
};

export { data };
