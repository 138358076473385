import React from 'react';
import Footer from './components/footer/Footer';
import Nav from './components/nav/Nav';
import AreasWork from './pages/AreasWork';
import ImageCarouselPage from './pages/ImageCarouselPage';
import BiographyPage from './pages/BiographyPage';
import ContactPage from './pages/ContactPage';

// const baseURL = process.env.REACT_APP_BASE_URL;

function App() {

  return (
    <div>
      <Nav />
      <div className="container-fluid container-md mt-5">
        <h1 className="fs-2 text-center my-5">Advokat Velimir Lipovan</h1>
        <ImageCarouselPage />
        <h2 id="works-page" className="fs-2 text-center  my-5">
          Oblasti rada
        </h2>
        <AreasWork />
        <h2 id="biography" className="fs-2 text-center  my-5">
          Biografija
        </h2>
        <BiographyPage />
        <h2 id="contact" className="fs-2 text-center  my-5">
          Kontakt
        </h2>
        <ContactPage />
        <a style={{ width: '48px' }} className="d-block ms-auto" href="#root">
          <h3 className="fs-1 fst-normal text-center m-0">&#8686;</h3>
        </a>
      </div>
      <Footer />
    </div>
  );
}

export default App;
