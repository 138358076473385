import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/scss/alice-carousel.scss';
import '../assets/scss/carousel.scss';

const slider_1 = require('../assets/img/slider_1.webp');
const slider_2 = require('../assets/img/slider_2.webp');
const slider_3 = require('../assets/img/slider_3.webp');
const slider_4 = require('../assets/img/slider_4.webp');
const slider_5 = require('../assets/img/slider_5.webp');
const slider_6 = require('../assets/img/slider_6.webp');
const slider_7 = require('../assets/img/slider_7.webp');
const slider_8 = require('../assets/img/slider_8.webp');
const slider_9 = require('../assets/img/slider_9.webp');

const ImageCarouselPage = () => {
  const items = [
    <div className="item">
      <img className="slider-img" src={slider_1} alt="slider" />
    </div>,
    <div className="item">
      <img className="slider-img" src={slider_2} alt="slider" />
    </div>,
    <div className="item">
      <img className="slider-img" src={slider_3} alt="slider" />
    </div>,
    <div className="item">
      <img className="slider-img" src={slider_4} alt="slider" />
    </div>,
    <div className="item">
      <img className="slider-img" src={slider_5} alt="slider" />
    </div>,
    <div className="item">
      <img className="slider-img" src={slider_6} alt="slider" />
    </div>,
    <div className="item">
      <img className="slider-img" src={slider_7} alt="slider" />
    </div>,
    <div className="item">
      <img className="slider-img" src={slider_8} alt="slider" />
    </div>,
    <div className="item">
      <img className="slider-img" src={slider_9} alt="slider" />
    </div>,
  ];

  const Carousel = () => {
    return (
      <AliceCarousel
        animationDuration={2000} // Number, default 400 - Set duration of animation.
        animationType // String(slide, fadeout), default slide - Set type of animation.
        autoWidth={true} // Boolean, default false - Set auto width mode.
        autoHeight={true} // Boolean, default false - Set auto height mode.
        autoPlay={true} // Boolean, default false - Set autoplay mode.
        autoPlayInterval={3000} // Number, default 400 - Set autoplay interval value.
        disableButtonsControls={false} // Boolean, default false - Disable buttons controls.
        disableSlideInfo={false} // Boolean, default true - Disable information about current slide.
        infinite={true} // Boolean, default false - Set infinite mode.
        items={items} // Array, default undefined - Set gallery items, preferable to use this property instead of children.
        keyboardNavigation={true} // Boolean, default false - Enable keyboard navigation, ArrowLeft - go to the prev slide,ArrowRight - go to the next slide,Space - run/stop autoplay mode if autoPlay property is equal true
        mouseTracking={true} // Boolean, default false - Enable mouse drag animation.
      />
    );
  };

  return <Carousel />;
};

export default ImageCarouselPage;
