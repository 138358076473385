import React, { useEffect, useState } from 'react';
import { MdWorkHistory } from 'react-icons/md';
import { FaUsers } from 'react-icons/fa';
import '../assets/scss/biography.scss';

const BiographyPage = () => {
  const Counter = ({ target }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5, // Prag za aktivaciju observacije (0 do 1, gde 1 znači da je element potpuno u vidokrugu)
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setCount(0); // Resetujemo brojač
            const increment = target / 200;
            const timer = setInterval(() => {
              setCount((prevCount) => {
                if (prevCount < target) {
                  return Math.ceil(prevCount + increment);
                } else {
                  return target;
                }
              });
            }, 50);
            return () => clearInterval(timer);
          }
        });
      }, options);

      observer.observe(document.querySelector('.counter'));

      return () => observer.disconnect();
    }, [target]);

    return <div className="counter">{count}</div>;
  };

  return (
    <div className="text-center biography">
      <div className="d-flex justify-content-around align-items-center">
        <div className="counter-container">
          <h6 className="fs-1 m-0">
            <MdWorkHistory />
          </h6>
          <Counter target={1997} />
          <span>Onsnovano</span>
        </div>
        <div className="counter-container">
          <h6 className="fs-1 m-0">
            <FaUsers />
          </h6>
          <Counter target={3000} />
          <span>Klijenata</span>
        </div>
      </div>

      <p className="mt-3">
        Rodjen sam u Kikindi 26.11.1966.g. gde sam završio osnovu i srednju
        školu. Po odsluženju vojnog roka završio sam Pravni fakultet
        Univerziteta u Novom Sadu i nakon staža od 2 godine koji sam proveo kao
        advokaski pripravnik, nakon polaganja pravosudnog ispita osnovao sam
        svoju advokatsku kancelariju 1997.godine u poslovnom prostoru porodične
        kuće na adresi Miloša Velikog 10 u Kikindi gde radim bez prekida do
        danas. Tokom rada kancelarije praktično i stručno sam osposobljen za
        zastupanje klijanata u svim oblastima prava pred domaćim sudovima kao i
        pred Evropskim sudom za ljudska prava u Strazburu. U Advokatskoj komori
        Vojvodine sam aktivan kao član izvršnog odbora Ogranka Zrenjanin u 2
        mandata a u Advokatskoj komori Srbije sam na funkciji dispciplinskog
        sudije AKS. Oženjen sam i otac sam dvoje punoletne dece.
      </p>
    </div>
  );
};

export default BiographyPage;
