import React from 'react';
import '../assets/scss/contact.scss';

const ContactPage = () => {
  return (
    <div className="contact-page">
      <div className="map-holder">
        <iframe
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2780.1395410238283!2d20.463797176668184!3d45.828488509058616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4744df284c3c6789%3A0xad72584a91b74299!2z0JzQuNC70L7RiNCwINCS0LXQu9C40LrQvtCzIDEwLCDQmtC40LrQuNC90LTQsA!5e0!3m2!1ssr!2srs!4v1711110513152!5m2!1ssr!2srs"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
      <div className="text-center mt-3">
        <p>Radno vreme: 8-12h i 17-19h radnim danima</p>
        <p>Adresa: Miloša Velikog 10, Kikinda</p>
        <p>
          Telefon / Fax:{' '}
          <a
            class="d-block text-decoration-none mb-3 title"
            href="tel:+381230422218"
          >
            0230/422-218
          </a>
        </p>
        <p>
          Mobilni:{' '}
          <a
            class="d-block text-decoration-none mb-3 title"
            href="tel:+38163544576"
          >
            063/544-576
          </a>
        </p>
        <p>
          E-mail:{' '}
          <a
            class="d-block text-decoration-none mb-3 title"
            href="mailto:lipovan.velimir@gmail.com"
          >
            lipovan.velimir@gmail.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default ContactPage;
