import React, { useState } from 'react';
import Logo from '../../assets/img/logo.png';

const Nav = () => {
  const [isActive, setIsActive] = useState(false);

  const toggleNav = () => {
    setIsActive(!isActive);
  };

  return (
    <div className="navigation d-flex flex-wrap justify-content-center justify-content-sm-between justify-content-md-around align-items-center gap-3 ">
      <div className="logo-holder">
        <img src={Logo} alt="Lipovan" className="img-fluid" />
      </div>
      <div className="nav-holder">
        <nav className={isActive ? 'active' : ''} id="nav">
          <ul>
            <li>
              <a href="#works-page">Oblasti</a>
            </li>
            <li>
              <a href="#biography">Biografija</a>
            </li>
            <li>
              <a href="#contact">Kontakt</a>
            </li>
          </ul>
          <button onClick={toggleNav} className="icon" id="toggle">
            <div className="line line1"></div>
            <div className="line line2"></div>
          </button>
        </nav>
      </div>
    </div>
  );
};

export default Nav;
