import React, { useEffect, useState } from 'react';
import { data } from '../components/data/Data';
import '../assets/scss/works.scss';

const AreasWork = () => {
  const [works, setWorks] = useState([]);

  useEffect(() => {
    if (data) {
      setWorks(data);
    }

    const checkBoxes = () => {
      const boxes = document.querySelectorAll('.work-box');
      const triggerBottom = (window.innerHeight / 5) * 4;
      boxes.forEach((box) => {
        const boxTop = box.getBoundingClientRect().top;
        const boxBottom = box.getBoundingClientRect().bottom;

        if (
          (boxTop < triggerBottom && boxBottom > 0) ||
          (boxTop < window.innerHeight && boxBottom > triggerBottom)
        ) {
          box.classList.add('show');
        } else {
          box.classList.remove('show');
        }
      });
    };

    window.addEventListener('scroll', checkBoxes);

    return () => {
      window.removeEventListener('scroll', checkBoxes);
    };
  }, []);

  const renderWorks = () => {
    if (!works || Object.keys(works).length === 0) {
      return null;
    }

    return Object.keys(works).map((key) => {
      const work = works[key];
      return (
        <div key={key} className="work-box my-3 show">
          <p className="work-box-text m-0">{work[0]}</p>
          <div className="text-secondary">
            <p className="m-0">{work[1]}</p>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="works-page">
      <div className="works-page-holder">{renderWorks()}</div>
    </div>
  );
};

export default AreasWork;
